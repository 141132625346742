.hero{
    display: flex;
    justify-content: space-between;
}
.left-h{
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem;
}
.right-h{
    flex: 1;
    position: relative;
    background-color: var(--orange);
}
.the-best-ad{
    padding: 20px 13px;
    border-radius: 4rem;
    width: fit-content;
    background-color: #363d42;
    text-transform: uppercase;
    color: var(--white);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start; 
}
.the-best-ad span{
    z-index: 3;
}
.the-best-ad div{
    position: absolute;
    background-color: var(--orange);
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
}
.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    text-overflow: inherit;
    font-weight: bold;
    color: var(--white);
    z-index: 99;
}
.hero-text div:nth-child(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}

.figures{
    display: flex;
    gap: 2rem;
    z-index: 99;
}
.figures div{
    display: flex;
    flex-direction: column;
}
.figures div span:nth-child(1){
    color: var(--white);
    font-size: 2rem;
}
.figures div span:nth-child(2){
    color: var(--gray);
    text-transform: uppercase;
    font-weight: 200;
}
.hero-buttons{
    display: flex;
    gap: 1rem;
    font-weight: normal;
}
.hero-buttons :nth-child(1){
    color: var(--white);
    background-color: var(--orange);
    width: 8rem;
}
.hero-buttons :nth-child(2){
    color: var(--white);
    background-color:transparent;
    border: 2px solid var(--orange);
    width: 8rem;
}
.right-h .btn{
    position: absolute;
    right: 3rem;
    top: 2rem;
    color: #111;
}
.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    align-items: flex-start;
    position: absolute;
    right: 4rem;
    top: 7rem;
    border-radius: 5px;
}
.heart-rate img{
    width: 2rem;
}
.heart-rate :nth-child(2){
    color: var(--gray);
}
.heart-rate :nth-child(3){
    color: var(--white);
    font-size: 1.5rem;
}
.hero-image{
    position: absolute;
    top: 8.5rem;
    right: 13rem;
    width: 23rem;
}
.hero-image-back{
    position: absolute;
    top: 10rem;
    right: 25rem;
    z-index: -1;
    width: 15rem;
}
@media  screen and (max-width:912px) {
    .hero-image {
        position: absolute;
        top: 8.5rem;
        right: 6rem;
        width: 23rem;
    }
    .hero-image-back {
        left: -19rem;
    }
}
@media  screen and (max-width:768px){
    .right-h {
        background: none;
    }
    .heart-rate{
        right: 4rem;
        gap: 0.2rem;
    }
    .heart-rate :nth-child(2) {
        font-size: 0.8rem;
    }
    .heart-rate :nth-child(3) {
        font-size: 1rem;
    }
    .hero-text {
        gap: 1rem;
        font-size: 3.5rem;
    }
    .hero-image {
        top: 11.5rem;
        right: 4rem;
        width: 17rem;
    }
    .hero-image-back {
        left: -13rem;
        width: 11rem;
        top: 12rem;
    }
    .figures {
        gap: 1rem;
    }
    .figures div span:nth-child(1) {
        font-size: 1.5rem;
    }
    .hero-buttons :nth-child(1),
    .hero-buttons :nth-child(2){
        width: 6rem;
    }
}
@media  screen and (max-width:560px){
    .hero{
        flex-direction: column;
    }
    .the-best-ad{
        align-self: center;
    }
    .hero-text {
        gap: 0.5rem;
        font-size: 3.2rem;
        align-items: center;
    }
    .hero-text>div>:nth-child(3){
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }
    .figures {
        gap: 0.5rem;
        align-self: center;
        margin-left: 3rem;
    }
    .hero-buttons {
        gap: 0.5rem;
        margin-left: 3rem;
    }
    .right-h{
        position: relative;
    }
    .hero-image {
        position: relative;
        align-self: center;
        width: 15rem;
        top: 2rem;
        margin-left: 12rem;
    }
    
}