.Footer-container{
    position: relative;
}
.Footer-container>hr{
    border: 1px solid var(--lightgray);
}
.footer{
    display: flex;
    padding: 1rem 2rem;
    flex-direction: column;
    align-items: center;
}
.social-links{
    display: flex;
    gap: 4rem;
}
.social-links>img{
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}