.header{
    display: flex;
    justify-content: space-between;
}
.logo{
    height: 12rem;
    width: 12rem;
    margin-top: -7rem!important;
    margin-left: -1rem;
    padding-left: 1.5rem;
}
.header-menu{
    display: flex;
    list-style: none;
    gap: 2rem;
    color: var(--white);
    align-items: center;
    margin-top: -2.5rem;
}
.header-menu li:hover{
    cursor: pointer;
    color: var(--orange);
}

@media  screen and (max-width:912px){
    .header-menu{
            gap: 0.5rem;
            margin-top: -3.5rem;
    }
    .logo {
        height: 10rem;
        width: 10rem;
        margin-top: -6rem!important;
        margin-left: -3rem;
        padding-left: 1.5rem;
    }
}
@media  screen and (max-width:768px){
   .header-menu{
    gap: 0.3rem;
   }
}

@media  screen and (max-width:560px){
    .header>:nth-child(2) {
        position: fixed;
        right: 4rem;
        z-index: 999;
        margin-top: 1rem;
        gap: 2rem;
    }
    .header-menu{
        flex-direction: column;
        padding: 2rem;
        background-color: var(--appColor);
    }
    .burger-menu{
        display: flex;
        flex-direction: column; 
        width: 2rem;
        height: 100%;
    }
    .burger-class{
        width: 2rem;
        background-color: var(--white);
        height: 0.3rem;
        margin-bottom: 0.5rem;
        border-radius: 10px;
    }
    .burger-menu>:nth-child(3){
        margin-bottom: 0;
    }

}
